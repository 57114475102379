export default class RequestDest{
    constructor(
        fwarehouseBean =0,
        fexpedisiBean =0,
        city ="",
        district ="",
        weight =0,
        serviceDisplay =0

    ) {
        this.fwarehouseBean = fwarehouseBean;
        this.fexpedisiBean = fexpedisiBean;
        this.city = city;
        this.district = district;
        this.weight = weight;
        this.serviceDisplay = serviceDisplay;

    }
}