import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";
import RequestDest from "@/models/ext/request-dest";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FSiCepatService {
    getApiUrl() {
        return API_URL
    }

    getPostSiCepatTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight, serviceDisplay){
        const requestDest = new RequestDest(
            fwarehouseBean,
            fexpedisiBean,
            kabKota,
            kecamatan,
            weight,
            serviceDisplay
        )

        // console.log(JSON.stringify(requestDest))

        return axios.post(API_URL + `getPostSiCepatTarif`, requestDest, { headers: authHeader() });
    }

    getSiCepatTracking(fwarehouseBean, fexpedisiBean, awbNumber){
        return axios.get(API_URL + `getSiCepatTracking/${fwarehouseBean}/${fexpedisiBean}/${awbNumber}`, { headers: authHeader() });
    }

    fetchFromSiCepatDestCodeApiAndSave(fdivisionBean, isSave){
        return axios.get(API_URL + `fetchFromSiCepatDestCodeApiAndSave/${fdivisionBean}/${isSave}`, { headers: authHeader() });
    }

}
export default new FSiCepatService()
