import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";
import RequestDest from "@/models/ext/request-dest";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FJneService {
    getApiUrl() {
        return API_URL
    }

    getPostJNETarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight){
        const requestDest = new RequestDest(
            fwarehouseBean,
            fexpedisiBean,
            kabKota,
            kecamatan,
            weight
        )
        return axios.post(API_URL + `getPostJNETarif`, requestDest, { headers: authHeader() });
    }

    getJNETarifWithParams(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight){
        // console.log(`${kabKota} >> ${kecamatan}`)
        return axios.get(API_URL + `getJNETarifWithParams?fwarehouseBean=${fwarehouseBean}&fexpedisiBean=${fexpedisiBean}&kabKota=${kabKota}&kecamatan=${kecamatan}&weight=${weight}`, { headers: authHeader() });
    }

    getJneTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight){
        return axios.get(API_URL + `getJNETarif/${fwarehouseBean}/${fexpedisiBean}/${kabKota}/${kecamatan}/${weight}`, { headers: authHeader() });
    }

    getJNETarifBasic(fwarehouseBean, fexpedisiBean, destCode, weight){
        return axios.get(API_URL + `getJNETarifBasic/${fwarehouseBean}/${fexpedisiBean}/${destCode}/${weight}`, { headers: authHeader() });
    }

    getJNEDestination(){
        return axios.get(API_URL + `getJNEDestination`, { headers: authHeader() });
    }

    getJNEDestCode(fwarehouseBean, fexpedisiBean, kabKota, kecamatan){
        return axios.get(API_URL + `getJNEDestCode/${fwarehouseBean}/${fexpedisiBean}/${kabKota}/${kecamatan}`, { headers: authHeader() });
    }
    fetchFromJneDestCodeApiAndSave(fdivisionBean, isSave){
        return axios.get(API_URL + `getJNEDestCodeAndSave/${fdivisionBean}/${isSave}`, { headers: authHeader() });
    }


    getJNETracking(fwarehouseBean, fexpedisiBean, awbNumber){
        return axios.get(API_URL + `getJNETracking/${fwarehouseBean}/${fexpedisiBean}/${awbNumber}`, { headers: authHeader() });
    }


}
export default new FJneService()
